<template>
  <div class="container">

    <a-layout   id="components-layout-demo-top-side">
      <a-layout-header  class="header">
<loginheader :activekey="1"></loginheader>
      </a-layout-header>
    </a-layout>
    <a-layout-content>
    <div class="content_banner">
      <img @click="godetail()" src="../../static/bbanner.png">
      <div class="newbottom">
          <h3>新闻动态:</h3><p>白纸坊小学成功签约中医药文化进校园</p>
      </div>
    </div>
  
    </a-layout-content>

    <a-layout-footer style="padding: 0;width: 100%;">
  <loginfooter></loginfooter>
    </a-layout-footer>
  </div>

</template>

<script>
import loginheader from "../home/components/loginheader";
import loginfooter from "../home/components/loginfooter";
export default {
  name: "newlogin",
  components:{
    loginheader:loginheader,
    loginfooter:loginfooter
  },
  data(){
    return {
      data:{


      }
    }
  },
  methods:{
    godetail(){
      this.$router.push('/logincontent')
    }

  }
}
</script>
<style>
@media (min-width: 1024px){
  body,html{font-size: 18px!important;}
}
@media (min-width: 1100px) {
  body,html{font-size: 20px!important;}
}
@media (min-width: 1280px) {
  body,html{font-size: 22px!important;}
}
@media (min-width: 1366px) {
  body,html{font-size: 24px!important;}
}
@media (min-width: 1440px) {
  body,html{font-size: 25px!important;}
}
@media (min-width: 1680px) {
  body,html{font-size: 28px!important;}
}
@media (min-width: 1920px) {
  body,html{font-size: 33px!important;}
}
</style>
<style scoped lang="less">
#components-layout-demo-top-side .header{
  height: 2.76rem;
  background: linear-gradient(-7deg, #C4EEED, #C1EAE9, #DAF7F2);

  box-sizing: border-box;



}
.content_banner{
  position: relative;
  width: 100%;
  height: 27rem;
  
  img{
    width: 100%;
    height: 100%;
  }
  .newbottom{
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 0.5rem;
    height: 40px;
    left: 5rem;
  z-index: 999;
  
    h3{
      font-size:1.03rem;
    color:#ffffff;
    font-weight: bold; 
    }
  
    font-size:1.03rem;
    color:#ffffff;
 

  }
}
.content_banner:hover{
  cursor: pointer;
}
::v-deep .ant-card-body{
  padding: 0.44rem;
}
.content_produce{

width: 100%;

}
.content_div{
  display: flex;
width: 20rem;

  position: relative;
  flex-direction: row;
  margin: 1rem auto;
}
.producediv{

}
.produceitem{
  .producetitle{
    h3{
      color: #131313;
      font-size: 0.8rem;
      font-family:SourceHanSansSC-Bold;
      font-weight: bold;
    }
    .newslinecontent{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 3rem;
    
      .newsline{
        height: 0.1rem;
        background: #F68C1C;
        width: 1.44rem;
      }

    }
  }

  .producelist
  {

    .producecard{
      width: 10rem;height: 2.13rem;margin-top: 0.4rem;
      font-size: 0.50rem;
      .newscontent{
        display: flex;
        flex-direction: row;
        height: 100%;width: 100%;

        .producedate{
          width: 2rem;
          color: #F68C1C;
        }
        a{
        
          color: #333333;
        }
      }
    }
    .producecard:hover{
      background:#46B872;
    }



  }
}
.bottom{
  padding: 0;
  z-index: 999;
  position: absolute;
  width: 10rem;

bottom:0;
  right: 0;
  width:11.8rem;
img{
  width: 100%;
}

}
</style>